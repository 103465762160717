import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Layout from "../layouts";
import Seo from "../components/seo";
import BreakpointUp from "../components/Media/BreakpointUp";
import CardType from "../components/CardType";
import {
  BreadCrumb,
  Section,
  SectionPageTitle,
  SubTitle
} from "../components/Section";

const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;
  ${BreakpointUp.lg`
        margin:0 -20px;
    `}
`;
const LeftSidbar = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  .isSticky {
    display: flex;
    align-items: center;
    justify-content: center;
    .card {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
    
  ${BreakpointUp.md`
    display:block;
    .isSticky {
      display: flex;
      align-items: center;
      justify-content: center;
      .card {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  `}
  ${BreakpointUp.lg`
      padding:0 20px;    
      flex: 0 0 25%;
      max-width: 25%;
      .isSticky {
        display: block;
      }
    `}
  ${BreakpointUp.xl`
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  `}   
    strong {
    display: block;
    margin-bottom: 10px;
    color: #000;
  }
`
const FaqContaniner = styled.div`
  position: relative;
  width: 100%;
  padding: 40px 40px 77px;
  order: 1;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  ${BreakpointUp.lg`
    padding:0 20px;
    flex: 0 0 70%;
    max-width: 70%;
    order:0;
  `}
  ${BreakpointUp.xl`       
    flex-basis: 0;
    flex-grow: 1;
    flex: 0 0 80%;
    max-width: 80%;
  `}
`

const FAQWrapper = styled.div`
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  box-shadow: 1px 4px 5px rgb(0 0 0 / 10%);
  margin-bottom: 20px;

  h4 {
    background: #f5f5f5;
    padding: 15px;
    font-size: 21px;
    font-weight: 400 !important;
  }

  p {
    padding: 15px;
    font-size: 16px;
    color: #000;
    font-weight: 300;
    margin: 0;
  }
`;

export default function FaqPage({ data, location }) {
  const pageData = data.allContentfulFaq.edges
  const [activeTab, setActiveTab] = useState("Portable Buildings");

  return (
    <Layout footerCta location={location}>
      <Seo title="Frequently asked Questions - Buildings And More" description="Have doubts about outdoor buildings? Here are some frequently asked questions that might help you." />
      <BreadCrumb position="relative">
        <div className="container">
          <Link to="/">Home</Link>
          <span>&nbsp;/&nbsp;</span>
          <span>FAQ</span>
        </div>
      </BreadCrumb>
      <Section bgColor="#F7F5F5" mb="50px">
        <div className="container">
          <SectionPageTitle mb="41px" textAlign="left">
            Frequently Asked Questions
          </SectionPageTitle>
          <MainPanel>
            <LeftSidbar>
              <div className="isSticky">
                <CardType
                  buildingtype="Portable Buildings"
                  buildingtitle="Portable Buildings"
                  isActive={activeTab === "Portable Buildings" && true}
                  handleTab={() => setActiveTab("Portable Buildings")}
                />
                <CardType
                  buildingtype="Steel Buildings"
                  buildingtitle="Steel Buildings"
                  isActive={activeTab === "Steel Buildings" && true}
                  handleTab={() => setActiveTab("Steel Buildings")}
                />
              </div>
            </LeftSidbar>
            <FaqContaniner>
              <SubTitle textAlign="left">
                { activeTab }
              </SubTitle>
              {pageData.map((item, i) => {
                if (item.node.category?.name === activeTab) {
                  return (
                    <FAQWrapper key={i}>
                      <h4>
                        {item.node.question}
                      </h4>
                      <div dangerouslySetInnerHTML={{__html: item.node.answer.childMarkdownRemark.html}} />
                    </FAQWrapper>
                  );
                } else {
                  return true;
                }
              })}
            </FaqContaniner>
          </MainPanel>
        </div>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query FaqPageQuery {
    allContentfulFaq {
      edges {
        node {
          category {
            name
          }
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
     
