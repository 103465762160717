import React from 'react'

const TriangleIcon = (props) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19">
      <path id="Path_18989" data-name="Path 18989" d="M8,0H21L12.118,8.036,0,19V8A8,8,0,0,1,8,0Z" fill="#D0D0D0"/>
    </svg>
  )
}

export default TriangleIcon